import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NoDeviceFound from "./NoDeviceFound";
import Dropdown from "../common/DropDown";
import { assetAssignmentEndPoints } from "../../services/apis";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import checkRoleAccess from "../shared/CheckRoleAcess";

const DeviceDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [updateUI, setUpdateUI] = useState(false);
  const [userId, setUserId] = useState("");
  const [assetBrandData, setAssetBrandData] = useState([]);
  const [assetTypeData, setAssetTypeData] = useState([]);
  const [formData, setFormData] = useState({
    device_id: null,
    assetType: "",
    customAssetType: "",
    assetSerialno: "",
    assetBrand: "",
    customBrand: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState({
    visible: false,
    isEditMode: false,
    deviceId: null,
  });
  // const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
  //   visible: false,
  //   deviceId: null,
  // });
  const [viewAssigned, setViewAssigned] = useState(true);
  const navigate = useNavigate();

  const filteredDevices = deviceData.filter(
    (device) =>
      device.device_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      device.device_brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
      device.device_serialnumber
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const closeModal = () => {
    setShowAddEditModal({
      visible: false,
      isEditMode: false,
      deviceId: null,
    });
    setFormData({
      assetType: "",
      customAssetType: "",
      assetSerialno: "",
      assetBrand: "",
      customBrand: "",
    });
  };

  const openModal = (device) => {
    setShowAddEditModal({
      visible: true,
      isEditMode: !!device,
      deviceId: device?.device_id || null,
    });
    setFormData({
      device_id: device?.device_id || null,
      assetType: device?.device_type || "",
      customAssetType:
        device?.device_type === "Others" ? device.customAssetType || "" : "",
      assetSerialno: device?.device_serialnumber || "",
      assetBrand: device?.device_brand || "",
      customBrand:
        device?.device_brand === "Others" ? device.customBrand || "" : "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!checkRoleAccess(8)) {
      return;
    }

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Include userId in the formData
    const updatedFormData = { ...formData, userId };

    console.log(updatedFormData);
    try {
      if (showAddEditModal.isEditMode) {
        const response = await axios.post(
          assetAssignmentEndPoints.POST_UPDATE_ASSET_DETAILS,
          updatedFormData
        );
        console.log("==Response", response);
        toast.success(
          // response.data?.data?.messageCode ||
          "Asset updated successfully"
        );
      } else {
        const response = await axios.post(
          assetAssignmentEndPoints.POST_ASSET_DETAILS,
          updatedFormData
        );
        toast.success(
          // response.data?.data?.messageCode ||
          "Asset added successfully"
        );
      }
      setUpdateUI((prevState) => !prevState);
      navigate("/admin/assets");
      closeModal();
    } catch (error) {
      toast.error(
        error.response?.data?.data?.messageCode || "Error handling asset"
      );
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      closeModal();
    }
  };

  const fetchDeviceData = async (url) => {
    try {
      const response = await axios.get(url);
      setDeviceData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleAssign = () => {
    fetchDeviceData(assetAssignmentEndPoints.GET_ASSIGN_ASSET_DETAILS);
  };

  const handleUnassign = () => {
    fetchDeviceData(assetAssignmentEndPoints.GET_UNASSIGN_ASSET_DETAILS);
  };

  const handleAll = () => {
    fetchDeviceData(assetAssignmentEndPoints.GET_ASSET_ASSIGNMENT);
  };

  useEffect(() => {
    fetchDeviceData(assetAssignmentEndPoints.GET_ASSET_ASSIGNMENT);
  }, [updateUI]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assetResponse = await axios.post(
          assetAssignmentEndPoints.POST_DISTINCT_ASSET_TYPE
        );
        setAssetTypeData(assetResponse.data?.data?.data || []);
        console.log("Asset Type======>", assetResponse.data?.data?.data);
      } catch (error) {
        console.error("Error fetching asset types:", error);
      }

      try {
        const brandsResponse = await axios.post(
          assetAssignmentEndPoints.POST_DISTINCT_ASSET_BRAND
        );
        setAssetBrandData(brandsResponse.data?.data?.data || []);
        console.log("Asset Brands======>", brandsResponse.data?.data?.data);
      } catch (error) {
        console.error("Error fetching asset brands:", error);
      }
    };

    fetchData();
  }, [updateUI]);

  const handleStatus = async (device_id, is_active) => {
    try {
      if (is_active === 1) {
        await axios.post(assetAssignmentEndPoints.POST_DEACTIVE_ASSET, {
          device_id,
        });
        toast.success("Process deactivated successfully");
      } else {
        await axios.post(assetAssignmentEndPoints.POST_ACTIVE_ASSET, {
          device_id,
        });
        toast.success("Process activated successfully");
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating process status:", error);
      toast.error("Error updating process status");
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id") || "";
    setUserId(storedUserId);
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Assets Master</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for an asset"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for an asset"
        />
        <div className="flex space-x-2">
          <Dropdown
            handleAll={handleAll}
            handleAssign={handleAssign}
            handleUnassign={handleUnassign}
          />
          <button
            onClick={() => openModal(null)}
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Asset"
          >
            Add Asset
          </button>
        </div>
      </div>
      {filteredDevices.length === 0 ? (
        <NoDeviceFound />
      ) : (
        <div className="overflow-auto max-h-[800px] ">
          <table className="w-full table-auto border-collapse border-2 border-gray-300 rounded-lg">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Asset Type
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Asset Brand
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Asset Serial No.
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredDevices.map((device, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-200`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {device.device_type}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {device.device_brand}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {device.device_serialnumber}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openModal(device)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdEdit /> Edit
                      </button>
                      <button
                        className="text-sm px-3 py-1 rounded"
                        onClick={() =>
                          handleStatus(device.device_id, device.is_active)
                        }
                      >
                        {device.is_active === 1 ? "🟢 Active" : "🔴 Inactive"}
                      </button>
                      {/* <button
                        onClick={() =>
                          setShowDeleteConfirmModal({
                            visible: true,
                            deviceId: device.device_id,
                          })
                        }
                        className="text-red-500 text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdDelete /> Delete
                      </button> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showAddEditModal.visible && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center mb-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {showAddEditModal.isEditMode ? "Edit Asset" : "Add Asset"}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Asset Type
                </label>
                <select
                  name="assetType"
                  value={formData.assetType}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="" disabled>
                    Select Asset Type
                  </option>
                  {assetTypeData.map((type) => (
                    <option key={type} value={type.device_type}>
                      {type.device_type}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </select>
                {formData.assetType === "Others" && (
                  <input
                    name="customAssetType"
                    type="text"
                    value={formData.customAssetType}
                    onChange={handleChange}
                    className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Custom Asset Type"
                  />
                )}
              </div>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Asset Brand
                </label>
                <select
                  name="assetBrand"
                  value={formData.assetBrand}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="" disabled>
                    Select Asset Brand
                  </option>
                  {assetBrandData.map((brand) => (
                    <option key={brand} value={brand.device_brand}>
                      {brand.device_brand}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </select>
                {formData.assetBrand === "Others" && (
                  <input
                    name="customBrand"
                    type="text"
                    value={formData.customBrand}
                    onChange={handleChange}
                    className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Custom Brand"
                    required
                  />
                )}
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Asset Serial No.
                </label>
                <input
                  name="assetSerialno"
                  type="text"
                  value={formData.assetSerialno}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Asset Serial No."
                  required
                />
              </div>
              <div className="mt-4 flex justify-between">
                <button
                  type="submit"
                  className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {showAddEditModal.isEditMode ? "Update Asset" : "Add Asset"}
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="w-full bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* {showDeleteConfirmModal.visible && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center mb-4">
              <h1 className="text-xl text-gray-700 font-bold">
                Confirm Deletion
              </h1>
            </div>
            <p className="text-gray-700 mb-4">
              Are you sure you want to delete this asset?
            </p>
            <div className="flex justify-between">
              <button
                onClick={async () => {
                  try {
                    await axios.post(
                      assetAssignmentEndPoints.POST_DELETE_ASSET,
                      {
                        device_id: showDeleteConfirmModal.deviceId,
                      }
                    );
                    toast.success("Asset deleted successfully");
                    closeModal();
                    setUpdateUI((prevState) => !prevState);
                  } catch (error) {
                    console.error("Error deleting asset:", error);
                    toast.error("Error deleting asset");
                  }
                  setShowDeleteConfirmModal({ visible: false, deviceId: null });
                }}
                className="w-full bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Yes, Delete
              </button>
              <button
                type="button"
                onClick={() =>
                  setShowDeleteConfirmModal({ visible: false, deviceId: null })
                }
                className="w-full bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default DeviceDashboard;
