import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'
import toast from 'react-hot-toast'
import ButtonSpinner from '../components/common/ButtonSpinner'

const tableHeading = [
    "code",
    "title",
    "role",
    "experience",
    "type",
    "salary",
    "description"
]

const AddJobOpening = () => {
    const [tableData, setTableData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [addJob, setAddJob] = useState(false)
    const [editJob, setEditJob] = useState(false)
    const [modalData, setModalData] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchTableDate = async () => {
        setLoading(true);
        const jobs = await axios.get(`${process.env.REACT_APP_BASE_URL}/apply/getAllJobs`);
        // console.log(bloglist.data.data);
        setTableData(jobs.data.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchTableDate();
    }, [])
    
    const fetchPreFillData = (index) => {
        // console.log(index)
        const selectedTableData = tableData.find(item => item.id === index.id)
        setModalData(selectedTableData)
    }

    const handleEditButton = (e) => {
        setLoading(true);
        setAddJob(false)
        setEditJob(true);
        const index = tableData[parseInt(e.target.name)];
        // console.log(index);
        fetchPreFillData(index)
        setShowModal(true)
        setLoading(false);
        return true
    }

    const handleActivate = (e) => {
        setLoading(true)
        const tempData = [...tableData];
        const index = tempData[parseInt(e.target.name)];
        // console.log(index);
        axios.put(`${process.env.REACT_APP_BASE_URL}/apply/updateJobStatus/${index.id}`, { is_active: !index.is_active })
            .then((res) => {
                toast.success('Job Updated Successfully')
                index.is_active = !index.is_active;
                setTableData(tempData);
                setLoading(false)
            })
            .catch((err) => {
                toast.error('Something went wrong')
                setLoading(false)
            })
    }

    const handleAddBlog = () => {
        //  console.log('Add Job')
        setEditJob(false);
        setAddJob(true)
        setShowModal(true)
        setModalData({})
    }

    const handleOnClose = (e) => {
        if (e.target.id === 'outside') {
            setShowModal(false)
            setAddJob(false)
        }
    }

    const handleSubmit = () => {
        const requiredFields = ['title', 'code', 'description', 'salary', 'experience', 'role', 'type'];
        const emptyFields = requiredFields.filter(field => !modalData[field]);

        if (emptyFields.length > 0) {
            emptyFields.forEach(field => {
                toast.error(`Please enter ${field.split('_').join(" ")} field`);
            });
            return false;
        }

        setLoading(true);
        console.log("modalData: ", modalData);

        if (addJob) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/apply/addJob`, modalData)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    toast.success('Job Added Successfully');
                    fetchTableDate();
                    setAddJob(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong');
                    setLoading(false);
                });
        } else {
            axios.put(`${process.env.REACT_APP_BASE_URL}/apply/updateJob`, modalData)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    toast.success('Job Updated Successfully');
                    fetchTableDate();
                    setAddJob(false);
                    setEditJob(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong', err);
                    setLoading(false);
                });
        }
        return true;
    };

    const handleAddFormChange = (e) => {
        const tempData = { ...modalData }
        console.log('tempData: ', tempData);
        console.log(e.target.name);
        if (e.target.name === 'publishPostToggle') {
            tempData["isPublished"] = e.target.checked;
        }
        else {
            tempData[e.target.name] = e.target.value;
        }
        setModalData(tempData)
    }

    const handleContentChange = (e) => {
        const tempData = { ...modalData }
        console.log(tempData);
        tempData["description"] = e
        setModalData(tempData)
    }

    return (
        <div className='w-full h-full'>
            <div className="flex justify-between p-3 w-full">
                <h1 className="uppercase text-xl">Blogs</h1>
                <button onClick={handleAddBlog} className="py-1 px-2 rounded-md bg-primary text-white/80 text-xl uppercase cursor-pointer">
                    Add
                </button>
            </div>

            <div className="relative mt-12 overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                        <tr className='text-center'>
                            <th scope="col" className="px-6 py-3">S. No</th>
                            {
                                tableHeading.map((item, index) => {
                                    return (
                                        <th scope="col" className="px-6 py-3" key={index}>{item.split('_').join(" ")}</th>
                                    );
                                })
                            }
                            <th scope="col" className="px-6 py-3">Edit</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((item, index) => {
                                return (
                                    <tr className='bg-white border-b' key={index}>
                                        <td className="px-6 py-4 text-center w-2/12">{index + 1}.</td>
                                        {
                                            tableHeading.map((field, index) => {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={index}>
                                                        <div className='whitespace-nowrap'>
                                                            {

                                                                item[field] && item[field].length > 30 ?
                                                                    item[field].slice(0, 27) + '...'
                                                                    : item[field] || ''
                                                            }
                                                        </div>
                                                    </td>
                                                );
                                            })
                                        }
                                        <td className="px-6 py-4 text-center">
                                            <button onClick={handleEditButton} name={index} className='py-1 px-2 rounded-md bg-primary text-white/80 cursor-pointer'>Edit</button>
                                        </td>
                                        <td className="px-6 py-4 text-center">
                                            <button onClick={handleActivate} value={item.is_active} name={index} className={`py-1 px-2 rounded-md text-white/80 cursor-pointer ${item.is_active ? "bg-red-900" : "bg-primary"}`}>{item.is_active ? "Deactivate" : "Activate"}</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>

            {showModal && <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-[40] flex justify-center items-center' id='outside' onClick={handleOnClose}>
                <div className='shadow-lg h-4/5 w-6/12 mx-auto bg-white rounded-xl p-8 overflow-y-scroll z-[40000]'>
                    <div className='text-center text-3xl font-bold mb-8'>{addJob ? 'Add' : 'Edit'} Job</div>

                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col'>
                            <div className='flex justify-between flex-wrap'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Title</label>
                                    <input
                                        type="text"
                                        name='title'
                                        required
                                        placeholder={`Enter a title ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["title"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='code'>Code</label>
                                    <input
                                        type="text"
                                        name='code'
                                        required
                                        placeholder={`Enter a code ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["code"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap mt-5'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Role</label>
                                    <input
                                        type="text"
                                        name='role'
                                        required
                                        placeholder={`Enter a Role ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["role"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Experience</label>
                                    <input
                                        type="text"
                                        name='experience'
                                        required
                                        placeholder={`Enter a Experience ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["experience"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap mt-5'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Type</label>
                                    <input
                                        type="text"
                                        name='type'
                                        required
                                        placeholder={`Enter a type ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["type"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Salary</label>
                                    <input
                                        type="text"
                                        name='salary'
                                        required
                                        placeholder={`Enter a salary ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["salary"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>
                            </div>



                            <div className='flex justify-between items-center flex-wrap mt-5'>
                                {!editJob && <div className='flex flex-col gap-2 w-[49%]'>
                                    <div className='flex justify-start items-center gap-4'>
                                        <label htmlFor="publishPostToggle" className="form-check-label">
                                            Publish this Post
                                        </label>
                                        <input
                                            type="checkbox"
                                            id="publishPostToggle"
                                            name="publishPostToggle"
                                            onChange={handleAddFormChange}
                                            checked={modalData["isPublished"] || false}
                                            className="form-check-input me-2"
                                        />
                                    </div>
                                </div>}
                            </div>

                            <div className='flex flex-col gap-2 mt-5'>
                                <ReactQuill
                                    className="h-[90%]"
                                    theme="snow"
                                    value={modalData["description"]}
                                    onChange={handleContentChange}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ],
                                        clipboard: {
                                            matchVisual: false,
                                        }
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video'
                                    ]}
                                    placeholder='Write something awesome ...'
                                />
                            </div>

                            <div className='w-full flex justify-center items-center'>
                                <button type="submit" className='mt-8 py-4 px-12 rounded-md text-xl bg-primary text-white w-full'>
                                    {loading ? <span>Loading...</span> : <span>Submit</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
            {loading && <ButtonSpinner />}
        </div>
    )
}

export default AddJobOpening