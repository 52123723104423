import React, { useState } from "react";
import banner from "../assets/about/about-banner.png";
import about_image from "../assets/about/about-image-1.png";
import ceo_image from "../assets/about/nikhil_pic.jpg";
import CTAButton from "../components/common/CTAButton";
import story1_image from "../assets/about/our-story-image1.png";
import story2_image from "../assets/about/our-story-image2.png";
import story3_image from "../assets/about/our-story-image3.png";
import { Link } from "react-router-dom";
import { submitSubscriptionForm } from "../services/operations/formAPI";
import Banner from "../components/common/Banner";
import { Helmet } from "react-helmet-async";

const About = () => {
  const data = [
    {
      timeline: "2017",
      content:
        "The company was started 6 years ago by providing <b>RPA solutions</b> to <b>optimize business efficiency</b> and maintenance services for <b>web design</b> and <b>app development services </b>. The company's <b>timely delivery</b> of solutions, and <b>meticulous detailing</b> caught the eye of our current overseas customer.",
      alt: "story-image-3",
      image: story1_image,
      flex_direction_reverse: false,
    },
    {
      timeline: "2022",
      content:
        "Today, we are serving the <b>USA, UK, EUROPE,</b> and <b>UAE</b> based on <b>entrepreneurs</b> and <b>reputed enterprises</b>. Our client base ranges from <b>Finance</b> to <b>Manufacturing</b> and <b>Supply Chain</b> to <b>Utilities</b>.",
      alt: "story-image-3",
      image: story2_image,
      flex_direction_reverse: true,
    },
    {
      timeline: "2024 onwards",
      content:
        "We will continue to advance our expertise in <b>full-stack development</b>, cutting edge technologies like <b>RPA</b> and <b>ChatGPT</b>, work to add <b>iOS</b> and <b>mobile app</b> capabilities, and learn new skills in <b>data analytics</b> and <b>cybersecurity</b>.",
      alt: "story-image-3",
      image: story3_image,
      flex_direction_reverse: false,
    },
  ];

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setEmail("");
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        email: email,
      };

      console.log(formData);

      setLoading(true);
      const result = await submitSubscriptionForm(formData);
      if (result) {
        resetForm();
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Mittal Software Labs | About</title>
      </Helmet>

      <Banner title={"About Us"} banner={banner} />

      <div className="flex gap-16 justify-between items-center w-10/12 mx-auto my-16">
        <div className="w-[40%] md:block hidden">
          <img src={about_image} alt="about_image" />
        </div>
        <div className="flex flex-col gap-8 md:w-[45%]">
          {/* <h3 className='text-xl text-primary text-center md:text-left'>- Who we are -</h3> */}
          <h1 className="text-5xl font-bold text-primary text-center md:text-left">
            About Us
          </h1>
          <p className="text-sm md:text-lg text-center md:text-left">
            At Mittal Software Labs, we specialize in <b>Robotic Process Automation
            (RPA)</b>, transforming businesses with <b>cutting-edge software solutions</b>.
            As a leading <b>software development company</b>, we are committed to
            delivering innovative and efficient <b>automation technologies</b> that <b>streamline processes</b> and <b>enhance productivity</b>. Our dedicated team of
            experts leverages the latest advancements in RPA to create <b>tailored
            solutions</b> that meet the unique needs of our clients,<b> driving success</b> and fostering growth in an ever-evolving <b>digital landscape</b>.
          </p>
          <div>
            <CTAButton linkto={"/advisory-board"}>Discover Our Team</CTAButton>
          </div>
        </div>
      </div>

      <div className="my-16 py-16 bg-primary text-white">
        <div className="flex flex-col gap-8 w-11/12 mx-auto">
          <h1 className="text-[3.2rem] text-center font-bold">Our Story</h1>
          <div className="flex flex-col gap-8">
            {data.map(
              (
                { timeline, content, image, alt, flex_direction_reverse },
                index
              ) => {
                return (
                  <div
                    key={index}
                    className={`flex ${
                      flex_direction_reverse
                        ? "flex-col-reverse md:flex-row-reverse"
                        : "flex-col-reverse md:flex-row"
                    } justify-between items-center gap-8 w-11/12 mx-auto`}
                  >
                    <p className="md:w-1/3 text-sm md:text-lg leading-6 text-center md:text-left">
                      {<div dangerouslySetInnerHTML={{ __html: content}} />}
                    </p>
                    <p className="py-2 px-4 rounded-lg bg-white text-primary font-bold text-md lg:text-xl">
                      {timeline}
                    </p>
                    <div className="w-1/3 hidden md:block">
                      <img src={image} alt={alt} />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      <div className="w-11/12 lg:10/12 mx-auto my-16 flex flex-col gap-8 justify-center">
        <h1 className="text-primary text-5xl font-bold text-center">Our CEO</h1>
        <div className="flex flex-col md:flex-row gap-8 md:gap-0 lg:gap-16 justify-center items-center">
          <div className="lg:w-[40%]">
            <div className="bg-primary text-white border rounded-lg border-primary p-5 m-5 flex flex-col justify-center gap-2 w-2/3 mx-auto">
              <img
                className="mb-2"
                src={ceo_image}
                alt="about_image"
                width={300}
                loading="lazy"
              />
              <h1 className="text-center font-bold text-xl">Nikhil Mittal</h1>
              <p className="text-center font-bold text-lg">CEO</p>
            </div>
          </div>
          <div className="flex flex-col gap-8 md:w-[45%] text-center md:text-left">
            <p className="text-sm md:text-md lg:text-xl">
              <b>Nikhil Mittal</b> is the <b>CEO</b> of <b>Mittal Software Labs</b>, overseeing a
              team of more than 200 professionals involved in diverse global
              projects. <b>MSL</b> helps forward-thinking businesses reconsider their
              operations by providing <b>inventive technology solutions</b>, services,
              and products.
            </p>
            <p className="text-sm md:text-md lg:text-xl">
              As the <b>CEO</b>,<b> Nikhil Mittal</b> has guided <b>MSL's</b> approach to utilizing <b>disruptive technologies</b> like <b>robotic process automation, cloud
              computing, system integration, cybersecurity, data analytics, </b> and <b>application development</b>. This approach has set the stage for <b>MSL's
              swift expansion </b>, establishing it as one of the quickest-growing
              companies in its specified markets. Additionally, he has played a
              crucial role in fostering and expanding a new range of products
              and platforms, which are positioned to become a <b>billion-dollar
              business segment</b> for the company.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-primary text-white mt-16 mb-4">
        <div className="w-11/12 mx-auto flex flex-col md:flex-row-reverse md:gap-16 justify-between items-center md:p-16 py-8">
          <div className="w-[40%] hidden md:block">
            <img src={about_image} alt="about_image" />
          </div>
          <div className="flex flex-col gap-8 md:w-[45%]">
            <h1 className="text-2xl lg:text-5xl font-bold text-center md:text-left">
              Join Our MSL Team
            </h1>
            <p className="md:text-lg text-center md:text-left">
              The company was started 6 years ago by providing <b>RPA solutions</b> to
              optimize business efficiency and maintenance services for <b> web
              design</b> and <b>app development</b> services. The company's timely delivery
              of solutions, and meticulous detailing caught the eye of our
              current overseas customer.
            </p>
            <div>
              <Link to="/contact">
                <div
                  className={`text-center text-[13px] sm:text-[16px] px-6 py-3 rounded-md font-bold shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] hover:shadow-none hover:scale-95 transition-all duration-200 bg-white text-primary`}
                >
                  Apply Now
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="w-11/12 mx-auto py-12">
        <div className="flex flex-col md:gap-4 justify-center items-center">
          <h1 className="text-[1.4rem] md:text-[2.4rem] lg:text-[3.2rem] font-bold text-center">
            Subscribe to Our Newsletter
          </h1>
          <p className="md:text-xl text-center mb-4">
            Stay up to date with our latest news and products
          </p>

          {/* <div className="w-1/3 mt-4 relative">
                        <input type="email" className="w-full p-3" placeholder="Enter your email" />
                        <button className="absolute py-2 px-4 right-0 top-1/2 -translate-y-1/2 text-xl cursor-pointer bg-primary text-white h-full">Subscribe</button>
                    </div> */}

          <form onSubmit={handleSubmit}>
            <div class="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
              <div class="relative w-full">
                <label
                  for="email"
                  class="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Email address
                </label>
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </div>
                <input
                  class="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary focus:border-primary"
                  placeholder="Enter your email"
                  type="email"
                  id="email"
                  required="true"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email}</span>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  class="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary border-primary sm:rounded-none sm:rounded-r-lg hover:bg-primary focus:ring-4 focus:ring-primary"
                >
                  Subscribe
                </button>
              </div>
            </div>
            <div class="mx-auto max-w-screen-sm text-sm text-left text-gray-500">
              We care about the protection of your data.{" "}
              <a
                href="#"
                class=" cursor-pointer font-medium text-primary hover:underline"
              >
                Read our Privacy Policy
              </a>
              .
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default About;
