import axios from "axios";
import React, { useEffect, useState } from "react";
import { assetReturnEndPoints } from "../../../services/apis.js";
import toast from "react-hot-toast";
import AssetsReturnCard from "./AssetsReturnCard.jsx";

const AssetsReturn = () => {
  const [userId, setUserId] = useState("");
  const [assetAcceptData, setAssetAcceptData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id") || "";
    setUserId(storedUserId);
  }, []);

  useEffect(() => {
    const fetchAssetAssignData = async () => {
      setLoading(true);
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        console.log("Fetching assets for userId:", userId);

        const response = await axios.post(
          assetReturnEndPoints.GET_ALL_RETURN_ASSETS,
          { userId }
        );
        const assets = response.data?.data?.data || [];
        console.log("Assets fetched:", assets);

        setAssetAcceptData(assets);
        console.log("State set with assets:", assets);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssetAssignData();
  }, [userId]);

  const refreshAssets = async () => {
    try {
      const response = await axios.post(
        assetReturnEndPoints.GET_ALL_RETURN_ASSETS,
        { userId }
      );
      const assets = response.data?.data?.data || [];
      setAssetAcceptData(assets);
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data. Please try again later.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-6  ">Asset Return</h1>
      <div className="bg-gray-100 shadow-md rounded-lg p-6">
        {loading ? (
          <p className="text-center text-gray-500">Loading asset details...</p>
        ) : assetAcceptData.length > 0 ? (
          assetAcceptData.map((asset, EdmId) => (
            <AssetsReturnCard
              key={EdmId}
              asset={asset}
              userId={userId}
              device_serialnumber={asset.device_serialnumber}
              refreshAssets={refreshAssets}
            />
          ))
        ) : (
          <p className="text-center text-gray-500">
            No assets found for the user.
          </p>
        )}
      </div>
    </div>
  );
};

export default AssetsReturn;
