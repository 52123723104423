import Select from "react-select";

const ProcessModal = ({
  editingProcess,
  formData,
  handleChange,
  handleSubmit,
  handleOutsideClick,
  projectData,
  technologyData,
  transformationLeverData,
}) => {
  const handleTechnologyChange = (selectedOptions) => {
    const selectedTechnologies = selectedOptions
      ? selectedOptions.map((option) => option.value).join(", ")
      : "";
    handleChange({
      target: {
        name: "technology_id",
        value: selectedTechnologies,
      },
    });
  };

  const handleTransformationLeverChange = (selectedOptions) => {
    const selectedLevers = selectedOptions
      ? selectedOptions.map((option) => option.value).join(", ")
      : "";
    handleChange({
      target: {
        name: "transformation_id",
        value: selectedLevers,
      },
    });
  };

  const technologyOptions = technologyData.map((technology) => ({
    value: technology.technology_id,
    label: technology.technology_name,
  }));

  const transformationLeverOptions = transformationLeverData.map((lever) => ({
    value: lever.transformation_id,
    label: lever.transformation_name,
  }));

  return (
    <div
      id="modal-overlay"
      onClick={handleOutsideClick}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-center m-4">
          <h1 className="text-2xl text-gray-700 font-bold">
            {editingProcess ? "Edit Process" : "Add Process"}
          </h1>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <fieldset className="border p-4 rounded-md">
            <legend className="text-sm font-bold text-gray-700 mb-4">
              Process Details
            </legend>

            <div className="space-y-4">
              {/* Process Id and Project Name */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="user_provided_process_id"
                  >
                    Process Id
                  </label>
                  <input
                    id="user_provided_process_id"
                    name="user_provided_process_id"
                    type="text"
                    value={formData.user_provided_process_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Id"
                    required
                    aria-label="Process Id"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="project_id"
                  >
                    Project Name
                  </label>
                  <select
                    id="project_id"
                    name="project_id"
                    value={formData.project_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Project Name"
                  >
                    <option value="" disabled>
                      Select Project
                    </option>
                    {projectData.map((project) => (
                      <option
                        key={project.project_id}
                        value={project.project_id}
                      >
                        {project.project_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Process Name and Process Owner */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_name"
                  >
                    Process Name
                  </label>
                  <input
                    id="process_name"
                    name="process_name"
                    type="text"
                    value={formData.process_name}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Name"
                    required
                    aria-label="Process Name"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_owner"
                  >
                    Process Owner
                  </label>
                  <input
                    id="process_owner"
                    name="process_owner"
                    type="text"
                    value={formData.process_owner}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Owner"
                    aria-label="Process Owner"
                  />
                </div>
              </div>

              {/* Technology Name and Function Name */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="technology_name"
                  >
                    Technology Name
                  </label>
                  <Select
                    id="technology_id"
                    name="technology_id"
                    value={technologyOptions.filter((option) =>
                      (formData.technology_id || "")
                        .split(", ")
                        .includes(option.value)
                    )}
                    onChange={handleTechnologyChange}
                    options={technologyOptions}
                    isMulti
                    className="mt-1 block w-full rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Technology Name"
                  />
                </div>

                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="function name"
                  >
                    Function Name
                  </label>
                  <select
                    id="function_name"
                    name="function_name"
                    value={formData.function_name}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Function Name"
                  >
                    <option value="" disabled>
                      Select Function Name
                    </option>

                    <option value="Retail/Commercial Operations">
                      Retail/Commercial Operations
                    </option>
                    <option value="Finance">Finance</option>
                    <option value="Supply Chain">Supply Chain</option>
                    <option value="Procurement">Procurement</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Order Management">Order Management</option>
                    <option value="IT">IT</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              {/* Process Description, Problem Statement, and Solution */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_description"
                  >
                    Process Description
                  </label>
                  <textarea
                    id="process_description"
                    name="process_description"
                    value={formData.process_description}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Description"
                    aria-label="Process Description"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="problem_statement"
                  >
                    Problem Statement
                  </label>
                  <textarea
                    id="problem_statement"
                    name="problem_statement"
                    value={formData.problem_statement}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Problem Statement"
                    aria-label="Problem Statement"
                  />
                </div>
                <div className="col-span-2">
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="solution"
                  >
                    Solution
                  </label>
                  <textarea
                    id="solution"
                    name="solution"
                    value={formData.solution}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Solution"
                    aria-label="Solution"
                  />
                </div>
              </div>

              {/* Annual Benefits, Dates, and Complexity */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="annualBenefits"
                  >
                    Annual Benefits
                  </label>
                  <input
                    id="annualBenefits"
                    name="annualBenefits"
                    type="text"
                    value={formData.annualBenefits}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Annual Benefits"
                    aria-label="Annual Benefits"
                  />
                </div>
                <div className="flex gap-4">
                  <div>
                    <label
                      className="block text-sm font-bold text-gray-700"
                      htmlFor="startDate"
                    >
                      Start Date
                    </label>
                    <input
                      id="startDate"
                      name="startDate"
                      type="date"
                      value={formData.startDate}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required
                      aria-label="Start Date"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-bold text-gray-700"
                      htmlFor="endDate"
                    >
                      End Date
                    </label>
                    <input
                      id="endDate"
                      name="endDate"
                      type="date"
                      value={formData.endDate}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      aria-label="End Date"
                    />
                  </div>
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="complexity"
                  >
                    Complexity
                  </label>
                  <select
                    id="complexity"
                    name="complexity"
                    value={formData.complexity}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Complexity"
                  >
                    <option value="" disabled>
                      Select Complexity
                    </option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="border p-4 rounded-md">
            <legend className="text-sm font-bold text-gray-700 mb-4">
              Additional Information
            </legend>
            <div className="space-y-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="purpose"
                  >
                    Purpose
                  </label>
                  <textarea
                    id="purpose"
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Purpose"
                    aria-label="Purpose"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="goals"
                  >
                    Goals
                  </label>
                  <textarea
                    id="goals"
                    name="goals"
                    value={formData.goals}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Goals"
                    aria-label="Goals"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="risks"
                  >
                    Risks
                  </label>
                  <textarea
                    id="risks"
                    name="risks"
                    value={formData.risks}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Risks"
                    aria-label="Risks"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="exceptions"
                  >
                    Exceptions
                  </label>
                  <textarea
                    id="exceptions"
                    name="exceptions"
                    value={formData.exceptions}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Exceptions"
                    aria-label="Exceptions"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                {/* 
              <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="technology_name"
                  >
                    Technology Name
                  </label>
                  <Select
                    id="technology_id"
                    name="technology_id"
                    value={technologyOptions.filter((option) =>
                      (formData.technology_id || "")
                        .split(", ")
                        .includes(option.value)
                    )}
                    onChange={handleTechnologyChange}
                    options={technologyOptions}
                    isMulti
                    className="mt-1 block w-full rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Technology Name"
                  />
                </div> */}

                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="transformation_name"
                  >
                    Transformation Levers
                  </label>
                  <Select
                    id="transformation_id"
                    name="transformation_id"
                    value={transformationLeverOptions.filter((option) =>
                      (formData.transformation_id || "")
                        .split(", ")
                        .includes(option.value)
                    )}
                    onChange={handleTransformationLeverChange}
                    options={transformationLeverOptions}
                    isMulti
                    className="mt-1 block w-full rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Transformation Levers"
                  />
                </div>

                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="application"
                  >
                    Application
                  </label>
                  <select
                    id="application"
                    name="application"
                    value={formData.application}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Application"
                  >
                    <option value="List Systems">List Systems</option>
                    <option value="Share Drive">Share Drive</option>
                    <option value="Web URLs">Web URLs</option>
                    <option value="SharePoint/ ShareDrive">
                      SharePoint/ ShareDrive
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="pt-4">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {editingProcess ? "Update Process" : "Add Process"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProcessModal;
