import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import ButtonSpinner from "../components/common/ButtonSpinner";
import moment from "moment";
import checkRoleAccess from "../components/shared/CheckRoleAcess";

const tableHeading = [
  "Employee Name",
  "From",
  "To",
  "Days",
  "Reason",
  "Accept",
  "Reject",
];

const WFHApplication = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch applications
  const fetchTableData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/wfhApplication/fetchAllApplications`
      );
      // console.log("API Response:", response.data);
      setTableData(response.data || []); // Ensure response.data is an array or use response.data.data if nested
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    const role = [1,8]
    if (!checkRoleAccess(role)) {
      return;
    }

  }, []);

  const calculateDays = (startDate, endDate) => {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).startOf('day');
  
    if (start.isAfter(end)) {
      return 0; // If start date is after end date, return 0
    }
  
    let totalDays = 0;
    let currentDate = start.clone();
  
    while (currentDate.isSameOrBefore(end)) {
      const dayOfWeek = currentDate.day();
      if (dayOfWeek !== 1 && dayOfWeek !== 6) { // Exclude Sunday (0) and Saturday (6)
        totalDays++;
      }
      currentDate.add(1, 'day');
    }
  
    return totalDays;
  };
  

  const handleAccept = async () => {
    setLoading(true);

    // Retrieve user_id from localStorage
    const user_id = localStorage.getItem("user_id");
    console.log("Accept User ID:", user_id); // Debugging line

    if (!user_id) {
      console.error("User ID is not set in localStorage.");
      toast.error("User ID not found.");
      setLoading(false);
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/wfhApplication/acceptApplication/${user_id}`
      );
      toast.success("Request accepted successfully");
      fetchTableData(); // Refresh data
    } catch (error) {
      console.error("Error accepting request:", error);
      toast.error("Failed to accept request");
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    setLoading(true);
    const user_id = localStorage.getItem("user_id");
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/wfhApplication/rejectApplication/${user_id}`
      );
      toast.success("Request rejected successfully");
      fetchTableData(); // Refresh data
    } catch (error) {
      console.error("Error rejecting request:", error);
      toast.error("Failed to reject request");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      {/* <div className="flex justify-between p-3 w-full">
        <h1 className="uppercase text-xl">WFH Applications</h1>
        <button onClick={insertApplications} className="bg-blue-500 text-white px-4 py-2 rounded">Insert Applications</button>
      </div> */}

      <div className="flex justify-start p-3 w-full">
        <h1 className="uppercase text-xl">admin</h1>
      </div>
      <div className="relative mt-12 overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-center">
              <th scope="col" className="px-6 py-3">
                S. No
              </th>
              {tableHeading.map((item, index) => (
                <th scope="col" className="px-6 py-3" key={index}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.length === 0 ? (
              <tr key="no-data">
                <td colSpan={tableHeading.length} className="text-center py-4">
                  No data available
                </td>
              </tr>
            ) : (
              tableData.map((item, index) => (
                <tr className="bg-white border-b" key={item.id || index}>
                  <td className="px-6 py-4 text-center">{index + 1}</td>
                  <td className="px-6 py-4 text-center">{item.created_by}</td>
                  <td className="px-6 py-4 text-center">
                    {new Date(item.start_date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {new Date(item.end_date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {calculateDays(item.start_date, item.end_date)}
                  </td>
                  <td className="px-6 py-4 text-center">{item.comments}</td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleAccept(item.id)}
                      className="py-1 px-2 rounded-md bg-green-500 text-white"
                    >
                      Accept
                    </button>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleReject(item.id)}
                      className="py-1 px-2 rounded-md bg-red-500 text-white"
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {loading && <ButtonSpinner />}
    </div>
  );
};

export default WFHApplication;
