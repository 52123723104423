import React from 'react';
import { useLocation } from 'react-router-dom';

function ProfileAD() {
    const location = useLocation();
    const { email } = location.state || {};

    if (!email) {
        return <div>Loading...</div>;
    }

    // console.log('Email:', email);

    return (
        <div>
            <h1>Welcome to your Profile Page</h1>
            <p>Email: {email}</p>
        </div>
    );
}

export default ProfileAD;
