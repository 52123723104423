import axios from "axios";
import { useEffect, useState, useCallback } from "react";

import { toast } from "react-toastify";
import NoDeviceFound from "./NoDeviceFound";
import {
  assetAllocationEndPoints,
  assetAssignmentEndPoints,
  userEndPoints,
} from "../../services/apis";
import { HelmetProvider, Helmet } from "react-helmet-async";
import checkRoleAccess from "../shared/CheckRoleAcess";

const AssetsAllocation = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assetAssignData, setAssetAssignData] = useState([]);
  const [assetBrandData, setAssetBrandData] = useState([]);
  const [assetTypeData, setAssetTypeData] = useState([]);

  const filteredAssetAssignData = assetAssignData.filter((assetAssign) => {
    const normalizedSearchTerm = searchTerm.toLowerCase();
    return (
      (assetAssign.name || "").toLowerCase().includes(normalizedSearchTerm) ||
      (assetAssign.device_type || "")
        .toLowerCase()
        .includes(normalizedSearchTerm) ||
      (assetAssign.device_brand || "")
        .toLowerCase()
        .includes(normalizedSearchTerm) ||
      (assetAssign.device_serialnumber || "")
        .toLowerCase()
        .includes(normalizedSearchTerm)
    );
  });

  const closeModal = useCallback(() => setShowModal(false), []);
  const openModal = useCallback(() => setShowModal(true), []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "assetBrand" && value !== "Others") {
      setFormData((prevData) => ({
        ...prevData,
        customBrand: "",
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    try {
      await submitForm();
      setUpdateUI((preState) => !preState);
      closeModal();
      resetForm();
      toast.success("Asset Assigned Successfully");
    } catch (error) {
      toast.error("Unable to assign asset");
      resetForm();
    }
  };

  const validateForm = () => {
    if (
      !formData.employeeId ||
      !formData.assetType ||
      !formData.assetSerialNo ||
      !formData.assetBrand ||
      !formData.assignDate
    ) {
      toast.error("Please fill in all required fields.");
      return false;
    }

    if (formData.assetType === "Others" && !formData.customAssetType) {
      toast.error("Please specify the custom asset type.");
      return false;
    }

    if (formData.assetBrand === "Others" && !formData.customBrand) {
      toast.error("Please specify the custom brand.");
      return false;
    }

    return true;
  };

  const submitForm = async () => {
    await axios.post(assetAllocationEndPoints.POST_ASSIGN_ASSET, formData);
    await axios.post(
      assetAssignmentEndPoints.POST_UPDATE_UNASIGNED_TO_ASSIGN,
      formData
    );
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsSubmitting(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") closeModal();
  };

  // useEffect(() => {
  //   if (!checkRoleAccess(8)) {
  //     return;
  //   }
  // }, []);

  const fetchData = useCallback(async () => {
    try {
      const [assetRes, empRes, brandRes, typeRes] = await Promise.all([
        axios.get(assetAllocationEndPoints.GET_ALL_ASSIGN_ASSETS),
        axios.get(userEndPoints.GET_ALL_USERS),
        axios.post(assetAssignmentEndPoints.POST_DISTINCT_ASSET_BRAND),
        axios.post(assetAssignmentEndPoints.POST_DISTINCT_ASSET_TYPE),
      ]);

      setAssetAssignData(assetRes?.data?.data?.data || []);
      setEmployeeData(empRes?.data?.data?.data || []);
      setAssetBrandData(brandRes?.data?.data?.data || []);
      setAssetTypeData(typeRes?.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data. Please try again later.");
    }
  }, []);

  // Fetch employee data on component mount or updateUI change
  useEffect(() => {
    const fetchAssetAssignData = async () => {
      try {
        const res = await axios.get(
          assetAllocationEndPoints.GET_ALL_ASSIGN_ASSETS
        );
        setAssetAssignData(res?.data?.data?.data || []);
      } catch (error) {
        console.error("Error fetching:", error);
        toast.error("Failed to fetch data. Please try again later.");
      }
    };

    fetchAssetAssignData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [updateUI, fetchData]);

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        const res = await axios.get(
          assetAssignmentEndPoints.GET_UNASSIGN_ASSET_DETAILS
        );
        setDeviceData(res?.data?.data?.data || []);
      } catch (error) {
        console.error("Error fetching devices:", error);
        toast.error("Failed to fetch devices. Please try again later.");
      }
    };

    fetchDeviceData();
  }, [updateUI]);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>Assets Allocation</title>
        </Helmet>
      </HelmetProvider>

      <h1 className="text-4xl font-bold mb-4">Assets Assignment</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search"
        />
        <button
          onClick={openModal}
          className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none"
          aria-label="Add Employee"
        >
          Assets Allocation
        </button>
      </div>

      {filteredAssetAssignData.length === 0 ? (
        <NoDeviceFound />
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <AssetAssignTable data={filteredAssetAssignData} />
        </div>
      )}

      {showModal && (
        <AssetAssignModal
          formData={formData}
          employeeData={employeeData}
          deviceData={deviceData}
          assetTypeData={assetTypeData}
          assetBrandData={assetBrandData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleOutsideClick={handleOutsideClick}
          closeModal={closeModal}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

const initialFormData = {
  employeeId: "",
  customAssetType: "",
  assetType: "",
  assetSerialNo: "",
  assetBrand: "",
  customBrand: "",
  assignDate: "",
};

const AssetAssignTable = ({ data }) => (
  <table className="w-full table-auto border-collapse border-2 border-gray-300">
    <thead className="bg-gray-100">
      <tr>
        <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
          Employee Name
        </th>
        <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
          Asset Type
        </th>
        <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
          Asset Brand
        </th>
        <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
          Asset Serial Number
        </th>
        <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
          Assign Date
        </th>
      </tr>
    </thead>
    <tbody>
      {data.map((assetAssign, index) => (
        <tr
          key={index}
          className={`bg-white ${
            index % 2 === 0 ? "bg-gray-50" : ""
          } hover:bg-gray-200`}
        >
          <td className="border border-gray-300 p-3 text-gray-700">
            {assetAssign.name}
          </td>
          <td className="border border-gray-300 p-3 text-gray-700">
            {assetAssign.device_type}
          </td>
          <td className="border border-gray-300 p-3 text-gray-700">
            {assetAssign.device_brand}
          </td>
          <td className="border border-gray-300 p-3 text-gray-700">
            {assetAssign.device_serialnumber}
          </td>
          <td className="border border-gray-300 p-3 text-gray-700">
            {assetAssign.assign_date}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const AssetAssignModal = ({
  formData,
  employeeData,
  deviceData,
  assetTypeData,
  assetBrandData,
  handleChange,
  handleSubmit,
  handleOutsideClick,
  closeModal,
  isSubmitting,
}) => {
  // Filter device data based on the selected asset brand
  const filteredDeviceData = deviceData.filter(
    (device) => device.device_brand === formData.assetBrand
  );

  return (
    <div
      id="modal-overlay"
      onClick={handleOutsideClick}
      className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={closeModal}
          aria-label="Close Modal"
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4">Asset Assignment</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="employeeId"
              className="block text-sm font-bold mb-1"
            >
              Employee:
            </label>
            <select
              id="employeeId"
              name="employeeId"
              value={formData.employeeId}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select an employee</option>
              {employeeData.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="assetType" className="block text-sm font-bold mb-1">
              Asset Type:
            </label>
            <select
              id="assetType"
              name="assetType"
              value={formData.assetType}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select an asset type</option>
              {assetTypeData.map((type, index) => (
                <option key={index} value={type.device_type}>
                  {type.device_type}
                </option>
              ))}
              <option value="Others">Others</option>
            </select>
            {formData.assetType === "Others" && (
              <input
                type="text"
                name="customAssetType"
                value={formData.customAssetType}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded mt-2"
                placeholder="Enter custom asset type"
                required
              />
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="assetBrand"
              className="block text-sm font-bold mb-1"
            >
              Asset Brand:
            </label>
            <select
              id="assetBrand"
              name="assetBrand"
              value={formData.assetBrand}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select an asset brand</option>
              {assetBrandData.map((brand, index) => (
                <option key={index} value={brand.device_brand}>
                  {brand.device_brand}
                </option>
              ))}
              <option value="Others">Others</option>
            </select>
            {formData.assetBrand === "Others" && (
              <input
                type="text"
                name="customBrand"
                value={formData.customBrand}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded mt-2"
                placeholder="Enter custom brand"
                required
              />
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="assetSerialNo"
              className="block text-sm font-bold mb-1"
            >
              Asset Serial No:
            </label>
            <select
              id="assetSerialNo"
              name="assetSerialNo"
              value={formData.assetSerialNo}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select a serial number</option>
              {filteredDeviceData.map((device) => (
                <option
                  key={device.device_serialnumber}
                  value={device.device_serialnumber}
                >
                  {device.device_serialnumber}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="assignDate"
              className="block text-sm font-bold mb-1"
            >
              Assign Date:
            </label>
            <input
              type="date"
              id="assignDate"
              name="assignDate"
              value={formData.assignDate}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 text-white p-2 rounded w-full font-bold ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-600"
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AssetsAllocation;
