import axios from "axios";
import React, { useState } from "react";
import {
  assetAssignmentEndPoints,
  assetReturnEndPoints,
} from "../../../services/apis";
import toast from "react-hot-toast";

const AssetsReturnCard = ({
  asset,
  userId,
  device_serialnumber,
  refreshAssets,
}) => {
  const [returning, setReturning] = useState(false);

  const fetchData = async (url, successMessage, setProcessing) => {
    try {
      setProcessing(true);

      const response = await axios.post(url, { userId, device_serialnumber });
      console.log("Response:", response);

      toast.success(successMessage);
      refreshAssets();
    } catch (error) {
      console.error("Error processing request:", error);
      toast.error("Failed to process the request. Please try again later.");
    } finally {
      setProcessing(false);
    }
  };

  const handleReturn = async () => {
    fetchData(
      assetReturnEndPoints.POST_RETURN_ASSET,
      "Asset return successfully!",
      setReturning
    );

    // const response = await axios.post(
    //   assetAssignmentEndPoints.POST_UPDATE_ASIGNED_TO_UNASSIGN,
    //   { device_serialnumber }
    // );

    // console.log("Response", response);
  };

  return (
    <div className="flex justify-between bg-white shadow-md rounded-lg p-6 mb-4 transition transform hover:scale-105 hover:shadow-lg">
      <div className="flex space-x-8">
        <div>
          <div className="text-lg font-bold text-gray-700">Device Name</div>
          <p className="text-md text-gray-900">{asset.device_type || "--"}</p>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-700">Device Brand</div>
          <p className="text-md text-gray-900">{asset.device_brand || "--"}</p>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-700">Serial Number</div>
          <p className="text-md text-gray-900">
            {asset.device_serialnumber || "--"}
          </p>
        </div>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          onClick={handleReturn}
          disabled={returning}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
        >
          {returning ? "Returning..." : "Return"}
        </button>
      </div>
    </div>
  );
};

export default AssetsReturnCard;
