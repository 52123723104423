import React, { useState, useRef, useEffect } from 'react';
import { submitInternshipApplication } from '../../services/operations/formAPI';
import axios from 'axios';

const InternForm = () => {
  // State variables to store form data
  const [fullName, setFullName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('India');
  const [collegeName, setCollegeName] = useState('');
  const [university, setUniversity] = useState('');
  const [collegeLocation, setCollegeLocation] = useState('');
  const [course, setCourse] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [batch, setBatch] = useState('');
  const [academicsAggregrate, setAcademicsAggregrate] = useState('');
  const [PANNumber, setPANNumber] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [linkedinProfile, setLinkedinProfile] = useState('');
  const [resume, setResume] = useState(null);
  const [collegeIdProof, setCollegeIdProof] = useState(null);
  const [identityProof, setIdentityProof] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const resumeInputRef = useRef(null);
  const collegeIdInputRef = useRef(null);
  const identityInputRef = useRef(null);

  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const courses = ['BCA', 'B.Tech', 'B.Sc', 'B.E.', 'MCA', 'M.Tech', 'M.Sc','MBA'];

  const specializations = [
    'CSE', 'ME', 'ECE', 'Data Science','IT','HR','Finance','Marketing','Operations Management','Business Analytics'
  ];
  const batches = ['2023', '2024', '2025', '2026', '2027', '2028'];
  const projectTechnologies = ['HTML', 'CSS', 'JS', 'Node.js', 'React.js', 'PHP', 'Flutter', 'SQL Server', 'My SQL', 'Angular.js'];
  // const cities = ['Delhi', 'Noida', 'Faridabad'];
  // const states = ['Delhi', 'Uttar Pradesh', 'Haryana', 'Rajasthan'];
  const Country = ['India'];

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/master/getStateandCities')
      .then(function (res) {
        const data = res.data.data;
        const states = Object.keys(data);
        setData(data);
        setStates(states);
        setCities(data[states[0]]);
      })
      .catch(function (e) {
        console.log(e);
      });
  }, []);

  const handleStateChange = (state) => {
    console.log(state);
    setState(state);
    console.log(data[state][0]);
    setCities(data[state]);
    setCity('');
  };

  const handleContactNoChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setContactNo(value);
      setErrors((prevErrors) => ({ ...prevErrors, contactNo: '' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, contactNo: 'Contact number cannot exceed 10 digits.' }));
    }
  };

  const academicsAggregrateRegex = /^(100(\.00?)?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;
  const PANNumberRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  const AadharNumberRegex = /^\d{12}$/;

  const validateForm = () => {
    const errors = {};

    if (!fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }
    if (!contactNo.trim()) {
      errors.contactNo = 'Contact No is required';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
    // if (!location.trim()) {
    //   errors.location = 'Location is required';
    // }
    if (!city.trim()) {
      errors.city = 'City/District is required';
    }
    if (!state.trim()) {
      errors.state = 'State/UT is required';
    }
    if (!country.trim()) {
      errors.country = 'Country is required';
    }
    if (!collegeName.trim()) {
      errors.collegeName = 'College Name is required';
    }
    if (!university.trim()) {
      errors.university = 'University is required';
    }
    if (!collegeLocation.trim()) {
      errors.collegeLocation = 'College Location is required';
    }
    if (!course.trim()) {
      errors.course = 'Course is required';
    }
    if (!specialization.trim()) {
      errors.specialization = 'Specialization is required';
    }
    if (!batch.trim()) {
      errors.batch = 'Batch is required';
    }
    if (!linkedinProfile.trim()) {
      errors.linkedinProfile = 'Linkedin Profile is required';
    }
    if (!academicsAggregrate.trim()) {
      errors.academicsAggregrate = 'Academic aggregate is required';
    } else if (!academicsAggregrateRegex.test(academicsAggregrate)) {
      errors.academicsAggregrate = 'Please enter a valid academic aggregate (e.g., 85.5%, 90%, 100%).';
    }
    if (PANNumber && !PANNumberRegex.test(PANNumber)) {
      errors.PANNumber = 'Please enter a valid PAN Number.';
    }
    if (aadharNumber && !AadharNumberRegex.test(aadharNumber)) {
      errors.aadharNumber = 'Please enter a valid Aadhar Number.';
    }
    if (!resume) {
      errors.resume = 'Resume is required';
    } else if (resume.type !== 'application/pdf') {
      errors.resume = 'Resume must be a PDF file';
    }
    if (!collegeIdProof) {
      errors.collegeIdProof = 'College ID Proof is required';
    } else if (collegeIdProof.type !== 'application/pdf') {
      errors.collegeIdProof = 'College ID Proof must be a PDF file';
    }
    if (!identityProof) {
      errors.identityProof = 'Identity Proof is required';
    } else if (identityProof.type !== 'application/pdf') {
      errors.identityProof = 'Identity Proof must be a PDF file';
    }

    setErrors(errors);
    console.log(errors);
    console.log(Object.keys(errors).length === 0);
    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setFullName('');
    setContactNo('');
    setEmail('');
    setCollegeName('');
    setCourse('');
    setSpecialization('');
    setBatch('');
    setResume(null);
    setCollegeIdProof(null);
    setIdentityProof(null);
    setMessage('');
    setState('');
    setCity('');
    setUniversity('');
    setCollegeLocation('');
    setAcademicsAggregrate('');
    setPANNumber('');
    setAadharNumber('');
    setLinkedinProfile('');
    resumeInputRef.current.value = '';
    collegeIdInputRef.current.value = '';
    identityInputRef.current.value = '';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('name', fullName);
      formData.append('mobile', contactNo);
      formData.append('email', email);
      formData.append('country', country)
      formData.append('state', state)
      formData.append('city', city)
      formData.append('college_name', collegeName);
      formData.append('university', university)
      formData.append('collegeLocation', collegeLocation)
      formData.append('course', course);
      formData.append('specialization', specialization);
      formData.append('batch', batch);
      formData.append('academicsAggregrate', academicsAggregrate)
      formData.append('linkedinProfile',linkedinProfile)
      formData.append('PANNumber', PANNumber)
      formData.append('aadharNumber', aadharNumber)
      formData.append('resume', resume);
      formData.append('collegeId', collegeIdProof);
      formData.append('identityProof', identityProof);
      formData.append('message', message);
      setLoading(true)
      const result = await submitInternshipApplication(formData);
      if (result) {
        resetForm();
      }
      setLoading(false)
    }
    else {
      console.log("regex failed");
    }
  };

  return (
    <div className='my-16'>
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col w-11/12 lg:w-3/3 mx-auto'>
          <div className='grid md:grid-cols-3 gap-5'>

            {/* Full Name */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="fullName">Full Name*</label>
              <input type="text" id="fullName" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
              {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
            </div>

            {/* Email */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="email">Email Address*</label>
              <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>

            {/* Contact No */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="contactNo">Contact No*</label>
              <input type="number" id="contactNo" placeholder="Contact No" value={contactNo} onChange={handleContactNoChange} />
              {errors.contactNo && <span className="text-red-500">{errors.contactNo}</span>}
            </div>

          </div>
          <div className='grid md:grid-cols-3 gap-5 mt-5'>
            <div className='flex flex-col gap-2'>
              <label htmlFor="Country">Country*</label>
              <select id="Country" value={country} onChange={(e) => setCountry(e.target.value)}>
                <option value="" disabled>Select Country</option>
                {Country.map((Country, index) => (
                  <option key={index} value={Country}>{Country}</option>
                ))}
              </select>
              {errors.country && <span className="text-red-500">{errors.country}</span>}
            </div>

            <div className='flex flex-col gap-2'>
              <label htmlFor="State">State/UT*</label>
              <select id="State" value={state} onChange={(e) => handleStateChange(e.target.value)}>
                <option value="" disabled>Select State/UT</option>
                {states.map((state, index) => (
                  <option key={index} value={state}>{state}</option>
                ))}
              </select>
              {errors.state && <span className="text-red-500">{errors.state}</span>}
            </div>

            <div className='flex flex-col gap-2'>
              <label htmlFor="city">City/District*</label>
              <select id="city" value={city} onChange={(e) => setCity(e.target.value)}>
                <option value="" disabled>Select City/District</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>{city}</option>
                ))}
              </select>
              {errors.city && <span className="text-red-500">{errors.city}</span>}
            </div>



          </div>


          <div className='grid md:grid-cols-3 gap-5 mt-5'>

            {/* College Name */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="collegeName">College Name*</label>
              <input type="text" id="collegeName" placeholder="College Name" value={collegeName} onChange={(e) => setCollegeName(e.target.value)} />
              {errors.collegeName && <span className="text-red-500">{errors.collegeName}</span>}
            </div>

            {/* University*/}
            <div className='flex flex-col gap-2'>
              <label htmlFor="University">University*</label>
              <input type="text" id="University" placeholder="University" value={university} onChange={(e) => setUniversity(e.target.value)} />
              {errors.university && <span className="text-red-500">{errors.university}</span>}
            </div>

            {/*College Location*/}
            <div className='flex flex-col gap-2'>
              <label htmlFor="College Location">College Location*</label>
              <input type="text" id="collegeLocation" placeholder="College Location" value={collegeLocation} onChange={(e) => setCollegeLocation(e.target.value)} />
              {errors.collegeLocation && <span className="text-red-500">{errors.collegeLocation}</span>}
            </div>

          </div>



          <div className='grid md:grid-cols-3 gap-5 mt-5'>
            {/* Course */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="course">Course*</label>
              <select id="course" value={course} onChange={(e) => setCourse(e.target.value)}>
                <option value="" disabled>Select Course</option>
                {courses.map((course, index) => (
                  <option key={index} value={course}>{course}</option>
                ))}
              </select>
              {errors.course && <span className="text-red-500">{errors.course}</span>}
            </div>

            {/* Specialization */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="specialization">Specialization*</label>
              <select id="specialization" value={specialization} onChange={(e) => setSpecialization(e.target.value)}>
                <option value="" disabled>Select Specialization</option>
                {specializations.map((spec, index) => (
                  <option key={index} value={spec}>{spec}</option>
                ))}
              </select>
              {errors.specialization && <span className="text-red-500">{errors.specialization}</span>}
            </div>

            {/* Batch */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="batch">Batch*</label>
              <select id="batch" value={batch} onChange={(e) => setBatch(e.target.value)}>
                <option value="" disabled>Select Batch</option>
                {batches.map((batch, index) => (
                  <option key={index} value={batch}>{batch}</option>
                ))}
              </select>
              {errors.batch && <span className="text-red-500">{errors.batch}</span>}
            </div>
          </div>

          <div className='grid md:grid-cols-3 gap-5 mt-5'>
            {/*Academics Aggregrate*/}
            <div className='flex flex-col gap-2'>
              <label htmlFor="Academics Aggregrate">Academics Aggregate (in %) *</label>
              <input type="text" id="academicsAggregrate" placeholder="Academics Aggregate" value={academicsAggregrate} onChange={(e) => setAcademicsAggregrate(e.target.value)} />
              {errors.academicsAggregrate && <span className="text-red-500">{errors.academicsAggregrate}</span>}
            </div>

            {/*Linkedin Profile URL*/}
            <div className='flex flex-col gap-2'>
              <label htmlFor="linkedinProfile">Linkedin Profile URL*</label>
              <input type="text" id="linkedinProfile" placeholder="Linkedin Profile URL" value={linkedinProfile} onChange={(e) => setLinkedinProfile(e.target.value)} />
              {errors.linkedinProfile && <span className="text-red-500">{errors.linkedinProfile}</span>}
            </div>

            {/*PAN Number*/}
            <div className='flex flex-col gap-2'>
              <label htmlFor="PAN Number">PAN Number (Optional) </label>
              <input type="text" id="PANNumber" placeholder="PAN Number" value={PANNumber} onChange={(e) => setPANNumber(e.target.value)} />
              {errors.PANNumber && <span className="text-red-500">{errors.PANNumber}</span>}
            </div>

            {/*Aadhar Number*/}
            <div className='flex flex-col gap-2'>
              <label htmlFor="Aadhar Number">Aadhar Number (Optional) </label>
              <input type="text" id="AadharNumber" placeholder="Aadhar Number" value={aadharNumber} onChange={(e) => setAadharNumber(e.target.value)} />
              {errors.aadharNumber && <span className="text-red-500">{errors.aadharNumber}</span>}
            </div>
          </div>

          <div className='grid md:grid-cols-3 gap-5 mt-5'>

            {/* Resume */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="resume">Resume*</label>
              <input type="file" id="resume" ref={resumeInputRef} onChange={(e) => setResume(e.target.files[0])} />
              {errors.resume && <span className="text-red-500">{errors.resume}</span>}
            </div>
            {/* College ID Proof */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="collegeIdProof">College Id Proof*</label>
              <input type="file" id="collegeIdProof" ref={collegeIdInputRef} onChange={(e) => setCollegeIdProof(e.target.files[0])} />
              {errors.collegeIdProof && <span className="text-red-500">{errors.collegeIdProof}</span>}
            </div>

            {/* Identity Proof */}
            <div className='flex flex-col gap-2'>
              <label htmlFor="identityProof">Identity Proof (Aadhar Card / Pan Card)*</label>
              <input type="file" id="identityProof" ref={identityInputRef} onChange={(e) => setIdentityProof(e.target.files[0])} />
              {errors.identityProof && <span className="text-red-500">{errors.identityProof}</span>}
            </div>
          </div>

          {/* Message */}
          <div className='flex flex-col gap-2 mt-5'>
            <label htmlFor="message">Message</label>
            <textarea type="text" maxLength={100} id="message" onChange={(e) => setMessage(e.target.value)} />
          </div>

          {/* Note */}

          <p className='mt-5'>Note: Please reach out at <a href="tel:+918595653061" className='border-b border-gray-800'>+91 8595653061</a>  for any assistance or issues encountered during the form submission process.</p>

          <div className='w-full flex justify-center items-center'>
            <button type="submit" disabled={loading} className='mt-8 py-4 px-12 rounded-md text-lg bg-primary text-white'>
              {loading ? <span>Loading...</span> : <span>Submit</span>}
            </button>
          </div>
        </div>
      </form >
    </div >
  );
};

export default InternForm;
