import React from 'react'
const WFHPolicy = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
            <div style={{ width: '100%', textAlign: 'right', marginRight: '100px' }}>
    
                </div>
                <p> <br /></p>
                <h1 style={{fontSize: '25px'}}>WFH Policy</h1>
                <p> <br />Our WFH Policy was last updated on August 04, 2024. <br /> <br /></p>
               
                <p>&#9679; <b>Maximum of Two Work from Home Days</b>: Employees will be allowed to take work
from home for a maximum of two days in a calendar month.<br /> Any additional work from
home days beyond this limit will be considered as leave and will be deducted from your
leave balance or will require additional approvals in advance. <br /> <br /> </p>

<p>&#9679; <b>Work From Home Requests</b>: All work from home applications need to be approved by
Nikhil Sir at least 2 days in advance. <br /> <br /> </p>

<p>&#9679; <b> Quarterly Recognition for In-Office Days</b>: To encourage a stronger presence in the
office, employees abstaining from Work from Home in a quarter will qualify for
surprise recognition.  <br /> <br /> </p>

<p>&#9679; <b>Leave Applications</b>: All leave, except for sick leave, must be applied for at least 7 days in
advance. <br /> <br /> </p>

<p>&#9679; <b>Sick Leave Documentation</b>:  More than 2 sick leave will require a medical document. <br /> <br /> </p>

<p>&#9679; <b>Unapproved Unavailability</b>: Any Unapproved Unavailability will be considered as noncompliance. <br /> <br /> </p>

<p>&#9679; <b>Compliance</b>: After 3 non-compliance events, the company reserves the right to take
appropriate actions on their own discretion<br /> <br /> </p>
              
    
            </div>
        </div>
       
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>
               You are advised to review this WFH Policy periodically for any changes. Changes to this
                    WFH Policy are effective when they are posted on this page. <br /></p>
                <p> <br />If you have any questions about this WFH Policy, you can contact us: <br />
                    <br /></p>
                <p>&#9679; By visiting this page on our website: https://mittalsoftwarelabs.com <br />&#9679; By sending us an email:
                   <a href='mailto:hr@mittalsoftwarelabs.com'></a> hr@mittalsoftwarelabs.com <br /></p>
                <p> </p>
    
            </div>
        </div>
        </div>
    </div>
    )
}

export default WFHPolicy;